import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  { path: 'inicio', loadChildren: () => import('./sections/home/home.module').then(m => m.HomeModule) },
  { path: 'menu', loadChildren: () => import('./sections/menu/menu.module').then(m => m.MenuModule) },
  { path: 'plato', loadChildren: () => import('./sections/plate-builder/plate-builder.module').then(m => m.PlateBuilderModule) },
  { path: 'sucursales', loadChildren: () => import('./sections/branches/branches.module').then(m => m.BranchesModule) },
  { path: 'ofertas', loadChildren: () => import('./sections/offers/offers.module').then(m => m.OffersModule) },
  { path: 'contacto', loadChildren: () => import('./sections/contact/contact.module').then(m => m.ContactModule) },
  { path: 'carrito', loadChildren: () => import('./sections/mycart/mycart.module').then(m => m.MyCartModule) },
  { path: 'ordenes-pendientes', loadChildren: () => import('./sections/mycurrentorders/mycurrentorders.module').then(m => m.MyCurrentOrdersModule) },
  { path: 'registro', loadChildren: () => import('./sections/user-sign-up/user-sign-up.module').then(m => m.UserSignUpModule) },
  { path: 'recuperar-contraseña', loadChildren: () => import('./sections/user-recover-password/user-recover-password.module').then(m => m.UserRecoverPasswordModule) },
  { path: 'perfil/:defaultTab', loadChildren: () => import('./sections/user-profile/user-profile.module').then(m => m.UserProfileModule) },
  { path: 'direcciones', loadChildren: () => import('./sections/address/address.module').then(m => m.AddressModule) },
  { path: 'detalle-orden', loadChildren: () => import('./sections/orderdetail/order-detail.module').then(m => m.OrderDetailModule) },
  { path: 'validar-correo', loadChildren: () => import('./sections/validate-pin/validate-pin.module').then(m => m.ValidatePinModule) },
  { path: 'reservaciones', loadChildren :() => import('./sections/reservations/reservations.module').then(m => m.MyReservationsModule)},
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
