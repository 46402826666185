import { Component } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: 'footer.component.html'
})
export class FooterComponent {
    public currentYear: string;
    constructor() {
        this.currentYear = new Date().getFullYear().toString();
    }
}