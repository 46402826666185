export const environment = {
  production: false,
  backend: 'https://dev.jadeapi.com/api/jade-api',
  offersImagesPath: 'https://jadeservice.azurewebsites.net/MyOffers/',
  appKeyPusher:'f1544781751c0d8fd38e',
  azulSite:'',
  azulMerchantId:'',
  cardnetUrl: 'https://lab.cardnet.com.do/servicios/tokens/v1/Scripts/PWCheckout.js',
  cardnetCaptureUrl: 'https://lab.cardnet.com.do/servicios/tokens/v1/Capture/',
  cardnetPublicKey: 'mfH9CqiAFjFQh_gQR_1TQG_I56ONV7HQ',
  cardnetPublicKeyAnonymous:'mfH9CqiAFjFQh_gQR_1TQG_I56ONV7HQ'
};